import { createGlobalStyle } from "styled-components";

const appColors = {
  templatea: {
    paddingContainer: "16px",
    paddingInner: "1.6rem",
    paddingTop: "32px",
    companyPrimary: "#61b345",
    companySecondary: "#059243",
    primary: "#333333",
    secondary: "#61B446",
    tertiary: "#007A35",
    quaternary: "#FF0000",
    quinary: "#CBA903",
    quinaryOpaque: "#FFFDF0",
    lightgray: "#D9D9D9",
    lightgray2: "#F0F0F0",
    lightgray3: "#EFF0F2",
    green: "#007934",
    inactive: "#f0f0f0",
    gray: "#666666",
    gray2: "#808080",
    gray3: "#DADADA",
    gray4: "#484848",
    gray5: "#9F9F9F",
    gray6: "#F6F6F6",
    darkgray: "#464749",
    light: "#FFFFFF",
    dark: "#000000",
    invalid: "#e60000",
    debtPending: "#b12323",
    valid: "#05a84c",
    validOpaque: "#f6feec",
    warning: "#f5a623",
    refunded: "#61b345",
    alertScheduling: "#cb0000",
    uberGray: "#ededed",
    uberBlue: "#006efa",
    taggy: {
      valid: "#61B345",
      invalid: "#979695",
    },
    home: {
      grid: 2,
      isList: false,
      boxShadow: "0 2px 2px 0 rgba(0,0,0,0.3)",
      pageBackground: "#EEF0F1",
      wrapperPadding: "16px",
      wrapperMargin: "0 0 20px",
      wrapperBorderRadius: "4px",
      iconMargin: "0 0 20px",
      iconAlignment: "left",
      iconSize: "24px",
      iconTextColor: "#808080",
      iconTextSize: "1.4rem",
      iconBackgroundColor: "#FFFFFF",
      iconBorder: "none",
      iconColor: "#808080",
      titleColor: "#808080"
    }
  },

  templateb: {
    paddingContainer: "2.0rem",
    paddingInner: "1.6rem",
    paddingTop: "7.2rem",
    companyPrimary: "#61b345",
    companySecondary: "#059243",
    primary: "#333333",
    secondary: "#61B446",
    tertiary: "#007A35",
    quaternary: "#FF0000",
    quinary: "#CBA903",
    quinaryOpaque: "#FFFDF0",
    lightgray: "#D9D9D9",
    lightgray2: "#F0F0F0",
    lightgray3: "#EFF0F2",
    gray: "#666666",
    gray2: "#808080",
    gray3: "#DADADA",
    gray4: "#484848",
    gray5: "#9F9F9F",
    gray6: "#F6F6F6",
    darkgray: "#464749",
    light: "#FFFFFF",
    dark: "#000000",
    invalid: "#E60000",
    valid: "#05A84C",
    validOpaque: "#F6FEEC",
    warning: "#F5A623",
    taggy: {
      valid: "#61B345",
      invalid: "#979695",
    },
    home: {
      grid: 3,
      isList: false,
      boxShadow: "none",
      pageBackground: "#FFFFFF",
      wrapperPadding: "0",
      wrapperMargin: "0 0 28px",
      wrapperBorderRadius: "0",
      iconMargin: "0 0 20px",
      iconAlignment: "center",
      iconSize: "32px",
      iconTextColor: "#808080",
      iconTextSize: "1.1rem",
      iconBackgroundColor: "transparent",
      iconBorder: "none",
      iconColor: "#4DADD3",
      titleColor: "#000000"
    }
  },

  templatec: {
    paddingContainer: "2.0rem",
    paddingInner: "1.6rem",
    paddingTop: "7.2rem",
    companyPrimary: "#61b345",
    companySecondary: "#059243",
    primary: "#333333",
    secondary: "#61B446",
    tertiary: "#007A35",
    quaternary: "#FF0000",
    quinary: "#CBA903",
    quinaryOpaque: "#FFFDF0",
    lightgray: "#D9D9D9",
    lightgray2: "#F0F0F0",
    lightgray3: "#EFF0F2",
    gray: "#666666",
    gray2: "#808080",
    gray3: "#DADADA",
    gray4: "#484848",
    gray5: "#9F9F9F",
    gray6: "#F6F6F6",
    darkgray: "#464749",
    light: "#FFFFFF",
    dark: "#000000",
    invalid: "#E60000",
    valid: "#05A84C",
    validOpaque: "#F6FEEC",
    warning: "#F5A623",
    taggy: {
      valid: "#61B345",
      invalid: "#979695",
    },
    home: {
      grid: 3,
      isList: false,
      boxShadow: "none",
      pageBackground: "#FFFFFF",
      wrapperPadding: "16px",
      wrapperMargin: "0 0 28px",
      wrapperBorderRadius: "6px",
      iconMargin: "0 0 20px",
      iconAlignment: "center",
      iconSize: "28px",
      iconTextColor: "#808080",
      iconTextSize: "1.1rem",
      iconBackgroundColor: "transparent",
      iconBorder: "solid 2px #dfdfdf",
      iconColor: "#4dadd3",
      titleColor: "#000000"
    }
  },

  templated: {
    paddingContainer: "2.0rem",
    paddingInner: "1.6rem",
    paddingTop: "7.2rem",
    companyPrimary: "#61b345",
    companySecondary: "#059243",
    primary: "#333333",
    secondary: "#61B446",
    tertiary: "#007A35",
    quaternary: "#FF0000",
    quinary: "#CBA903",
    quinaryOpaque: "#FFFDF0",
    lightgray: "#D9D9D9",
    lightgray2: "#F0F0F0",
    lightgray3: "#EFF0F2",
    gray: "#666666",
    gray2: "#808080",
    gray3: "#DADADA",
    gray4: "#484848",
    gray5: "#9F9F9F",
    gray6: "#F6F6F6",
    darkgray: "#464749",
    light: "#FFFFFF",
    dark: "#000000",
    invalid: "#E60000",
    valid: "#05A84C",
    validOpaque: "#F6FEEC",
    warning: "#F5A623",
    taggy: {
      valid: "#61B345",
      invalid: "#979695",
    },
    home: {
      grid: 1,
      isList: true,
      boxShadow: "none",
      pageBackground: "#FFFFFF",
      wrapperPadding: "16px",
      wrapperMargin: "0 0 20px",
      wrapperBorderRadius: "4px",
      iconMargin: "0 0 20px",
      iconAlignment: "left",
      iconSize: "24px",
      iconTextColor: "#4a4a4a",
      iconTextSize: "1.4rem",
      iconBackgroundColor: "transparent",
      iconBorder: "solid 1px #4a4a4a",
      iconColor: "#10bda7",
      titleColor: "#000000"
    }
  },
}

const gridOrientation = {
  templatea: {
    orientation: "list",
  },
  templateb: {
    orientation: "grid",
  },
  templatec: {
    orientation: "list",
  },
}

const media = {
  desktop: "@media (min-width: 769px)",
  tablet: "@media (max-width: 768px)",
  mobile: "@media (max-width: 480px)"
}

const GlobalStyle = createGlobalStyle`

  :root {
    --transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1);
    --transition-bg: background 280ms cubic-bezier(0.4, 0, 0.2, 1);
    --transition-border: border-color 280ms cubic-bezier(0.4, 0, 0.2, 1);
    --transition-shadow: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    --transition-opacity: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1);
    --transition-all: all 280ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  html, body {
    width: 100%;
    height: 100%;
    font-size: 62.5%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    section {
      padding-bottom: 24px !important;
    }
  }

  *, *:before, *:after {
    box-sizing: inherit
  }

  * {
    border: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  p, span, strong {
    font-size: 1.6rem;
    margin: 0;
  }

  strong {
    font-weight: bold;
  }

  button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.0);

    &:focus, &:active {
      outline: none !important;
      box-shadow: none !important;
      background-color: transparent !important;
    }
  }

  hr {
    height: 1px;
    margin: 16px 0;
    background-color: ${ appColors.templatea.lightgray };
  }

  .Toastify__toast-container {
    z-index: 9999;
    padding: 16px;

    > .Toastify__toast {
      border-radius: 8px;
      overflow: hidden;
    }

    .Toastify__toast-body {

      div {
        font-size: 1.4rem;
        font-weight: bold;
      }
    }

    .Toastify__close-button {
      align-self: center;
      margin-right: 5px;
    }
  }

  .switch-wrapper {
    position: relative;
  }

  .switch-wrapper > div {
    position: absolute;
  }

  .invalid {
    color: ${ appColors.templatea.invalid };
  }

  .valid {
    color: ${ appColors.templatea.valid };
  }

  .warning {
    color: ${ appColors.templatea.warning };
  }

`;

export {
  appColors as appColorsConfig,
  gridOrientation as gridOrientationConfig,
  media as mediaConfig,
  GlobalStyle as GlobalStyleConfig,
}