import styled from "styled-components";
import { IButtonGradientSC, IInputSC, IRowSC } from "./styles.interface";
import { mediaConfig } from "./themeConfig";

const LAYOUT = styled.div`
  &.authed {
    main {
      ${ mediaConfig.desktop } {
        padding-left: 120px;
      }
    }
`;
const CONTAINER = styled.div`
  width: 100%;
  padding: 0 ${ ({ theme }) => theme.paddingContainer };
  color: ${ ({ theme }) => theme.darkgray };

  ${ mediaConfig.desktop } {
    max-width: 1200px;
    margin: 0 auto;
  }
`

const ROW = styled.div<IRowSC>`
  display: flex;
  justify-content: space-between;

  > div {
    width: ${ ({ columns }) => columns ? `calc(100% / ${ columns })` : "48%" };
  }
`

const BUTTONOUTLINE = styled.div`
  > button, a {
    height: 44px;
    border-radius: 4px;
    border: solid 2px ${ ({ theme }) => theme.tertiary };
    background-color: ${ ({ theme }) => theme.light };
    margin: 20px auto;
    font-size: 1.4rem;
    font-weight: bold;
    color: #059243;
    width: 100%;
    text-decoration: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const BUTTONOUTLINEDISABLED = styled.div`
  > button, a {
    height: 44px;
    border-radius: 4px;
    border: solid 2px ${ ({ theme }) => theme.color };
    background-color: ${ ({ theme }) => theme.backgroundColor };
    margin: 20px auto;
    font-size: 1.4rem;
    font-weight: 900;
    color: #eeeeee;
    width: 100%;
    text-decoration: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const BUTTONGRADIENT = styled.div <IButtonGradientSC>`
  display: flex;

  > button, a {
    cursor: pointer;
    display: block;
    height: 44px;
    line-height: 44px;
    text-decoration: none;
    color: ${ ({ theme }) => theme.light };
    font-size: 1.4rem;
    font-weight: 600;
    border-radius: 4px;
    text-align: center;
    border: none;
    padding: 0 24px;
    background-image: linear-gradient(72deg, #059243 36%, #61b345 90%);
    text-transform: uppercase;
    ${ ({ isWarning }) => isWarning && "background-image: linear-gradient(99deg, #d98c0d 1%, #f5a623 100%)" };
    ${ ({ isDanger }) => isDanger && "background-image: linear-gradient(72deg, #9f041b 36%, #d53644 90%);" };

    &:disabled, &[data-disabled="disabled"] {
      opacity: 0.5;
      pointer-events: none;
    }

  }
`

const BUTTONGRADIENTFIXED = styled.div <IButtonGradientSC>`
  width: 100%;

  > button, a {
    position: fixed;
    bottom: 0;
    left: 0;
    display: block;
    background-image: linear-gradient(72deg, #059243 36%, #61b345 90%);
    height: 44px;
    line-height: 44px;
    text-decoration: none;
    color: #FFF;
    font-size: 1.4rem;
    font-weight: 600;
    border-radius: 4px;
    margin: 15px;
    text-align: center;
    border: none;
    width: ${ ({ isFullSize }) => isFullSize ? "100%" : "calc(100% - 32px)" };

    &:disabled, &[data-disabled="disabled"] {
      opacity: 0.5;
      pointer-events: none;
    }

  }
`

const BUTTONICON = styled.div <IButtonGradientSC>`
  width: 100%;

  > button, a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    text-decoration: none;
    font-size: 14px;
    border-radius: 8px;
    margin: 20px auto;
    padding: 20px 15px;
    font-weight: bold;
    border: solid 1px ${ ({ theme }) => theme.gray3 };
    color: ${ ({ theme }) => theme.darkgray };
    width: ${ ({ isFullSize }) => isFullSize ? "100%" : "calc(100% - 32px)" };

    > img {
      &:first-of-type {
        width: 20px;
        height: 20px;
      }

      &:last-of-type {
        width: 7px;
        height: 12px;
      }
    }
  }
`

const INPUT = styled.div <IInputSC>`
  position: relative;
  width: ${ ({ input }) => input === "checkbox" ? "auto" : "100%" };
  margin: 0 0 33px;

  ${ ({ input }) => input === "radio" ? `display: flex; flex-wrap: wrap;` : null };

  > input,
  > select,
  .input-group__select > input {
    border: none;
    outline: none;
    border-bottom: 1px solid ${ ({ theme }) => theme.gray };
    display: block;
    margin: ${ ({ input }) => input === "checkbox" ? "unset" : "0 auto" };
    background-color: transparent;
    font-size: 1.6rem;
    padding: 8px 0;
    width: ${ ({ input }) => input === "checkbox" ? "auto" : "100%" };
    color: ${ ({ theme }) => theme.darkgray };

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    &:-webkit-autofill {
      -webkit-text-fill-color: ${ ({ theme }) => theme.darkgray } !important;
    }

    &:focus, &.active {
      & + label {
        top: -10px;
        font-size: 1.2rem;
        font-weight: bold;
      }
    }
  }

  > select {
    padding: 0;
    margin-top: 0;
    color: ${ ({ theme }) => theme.gray };
  }

  > label {
    color: ${ ({ theme }) => theme.gray2 };
    font-size: 1.6rem;
    font-weight: normal;
    position: ${ ({ input }) => input === "checkbox" ? "static" : "absolute" };
    pointer-events: none;
    left: 0;
    top: 10px;
    transition: var(--transition-all);
  }

  > h4 {
    font-weight: bold;
    font-size: 1.2rem;
    color: ${ ({ theme }) => theme.gray2 };
    margin-bottom: 8px;
    width: 100%;
  }

  > div {
    ${ ({ input }) => input === "radio" ? `display: flex; width: 50%;` : null };

    > input[type=radio] {
      margin-right: 8px;
      position: relative;
      top: -1px;
    }

    > label {
      font-size: 1.4rem;
      color: ${ ({ theme }) => theme.gray };
    }
  }
`

const TITLE = styled.h2`
  letter-spacing: -0.4px;
  font-size: 2rem;
  line-height: 2rem;
  margin: 8px 0 16px;
  font-weight: bold;
  color: ${ ({ theme }) => theme.darkgray };

  ${ mediaConfig.desktop } {
    font-size: 2.4rem;
  }
`

const SUBTITLE = styled.p`
  letter-spacing: -0.4px;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: ${ ({ theme }) => theme.gray2 };

  ${ mediaConfig.desktop } {
    font-size: 1.6rem;
  }
`

const FOOTERACTIONS = styled.div`
  position: fixed;
  width: 100%;
  background-color: #f0f0f0;
  height: auto;
  bottom: 0;
  left: 0;
  padding: 5px 16px;
  text-align: center;
  display: flex;
  justify-content: space-between;

  .footer-actions {
    &__btn-back {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      width: 44px;
      height: 44px;
      font-size: 14px;
      font-weight: bold;
      color: #808080;
      margin-left: 20px;
    }

    &__btn-step {
      font-size: 14px;
      font-weight: bold;
      color: #007934;
      margin-right: 20px;
      margin-top: 0;

      &:disabled, &[data-disabled="disabled"] {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
`

const FOOTERACTIONSTAGGY = styled.div`
  position: fixed;
  width: 100%;
  background-color: #f0f0f0;
  height: auto;
  bottom: 0;
  left: 0;
  padding: 5px 16px;
  text-align: center;
  display: flex;
  justify-content: space-between;

  .footer-actions {
    &__btn-back {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      width: 44px;
      height: 44px;
      font-size: 14px;
      font-weight: bold;
      color: #808080;
      margin-left: 20px;
    }

    &__btn-step {
      font-size: 14px;
      font-weight: bold;
      color: #007934;
      margin-right: 20px;
      margin-top: 0;
    }
  }
`

export {
  LAYOUT,
  CONTAINER,
  ROW,
  BUTTONOUTLINE,
  BUTTONOUTLINEDISABLED,
  BUTTONGRADIENT,
  BUTTONGRADIENTFIXED,
  BUTTONICON,
  INPUT,
  TITLE,
  SUBTITLE,
  FOOTERACTIONS,
  FOOTERACTIONSTAGGY
}