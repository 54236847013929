import styled from "styled-components";
import { mediaConfig } from "../../styles/themeConfig";
import { SUBTITLE } from "../../styles/base.styles";

const LIST = styled.section`
  padding-top: 48px;

  .list {
    &__wrapper {
      ${ mediaConfig.desktop } {
        height: calc(100vh);
        width: 100%;
        max-width: 488px;
        margin: 0 auto;
      }

      > ${ SUBTITLE } {
        margin-bottom: 24px;
      }
    }

    &__warning {
      padding: 32px 24px;
      padding-top: 16px;
      border-radius: 8px;
      background-color: ${ ({ theme }) => theme.lightgray2 };

      > div {
        &:not(.warn) {
          display: flex;
          justify-content: flex-start;
          padding-left: 30px;
        }
      }
    }
  }
`;

const REGISTER = styled.section`
  padding-top: 48px;

  h5 {
    font-weight: bold;
    font-size: 1.2rem;
    color: ${ ({ theme }) => theme.gray2 };
    margin-bottom: 8px;
  }

  .register {
    &__wrapper {
      ${ mediaConfig.desktop } {
        height: calc(100vh);
        width: 100%;
        max-width: 488px;
        margin: 0 auto;
      }

      > ${ SUBTITLE } {
        margin-bottom: 24px;
      }

      form {
        margin-top: 24px;
      }
    }

    &__card-brands {
      list-style: none;
      display: flex;
      justify-content: space-between;
      padding: 0;
      margin-bottom: 32px;

      > li {
        width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 4px;
        background-color: ${ ({ theme }) => theme.lightgray2 };

      }

      > div {
        width: 100%;
      }
    }

    &__step {
      display: none;

      &.active {
        display: block;
      }
    }
  }`;

export {
  LIST,
  REGISTER,
}