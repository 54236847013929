import styled, { css } from "styled-components";

import { IDatepickerSC, IInputSC, ISwitcherSC } from "./Input.interface";


const INPUT = styled.div<IInputSC>`
  position: relative;
  width: ${ ({ inputType }) => inputType === "checkbox" ? "auto" : "100%" };
  margin: 0 0 32px;

  .input-group {
    position: relative;

    > select {
      cursor: pointer;
    }

    &__error {
      color: ${ ({ theme }) => theme.invalid };
      font-size: 1.1rem;
      font-weight: bold;
      margin-top: 4px;
      display: block;
    }

    &__input {
      border-bottom: 1px solid ${ ({ theme }) => theme.gray5 };
      border-top: none;
      border-left: none;
      border-right: none;
      display: block;
      margin: ${ ({ inputType }) => inputType === "checkbox" ? "unset" : "0 auto" };
      background-color: #FFF;
      font-size: 1.4rem;
      outline: none;
      padding: 16px 0 0;
      width: ${ ({ inputType }) => inputType === "checkbox" ? "auto" : "100%" };
      color: ${ ({ theme }) => theme.darkgray };

      &:not(textarea) {
        height: 48px;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }

      &:-webkit-autofill {
        -webkit-text-fill-color: ${ ({ theme }) => theme.darkgray } !important;
      }

      &:not(button) &:not([type="checkbox"]) {
        &:disabled, &:read-only {
          opacity: 0.5;
          cursor: not-allowed;
          pointer-events: none;
        }
      }

      &:focus, &.input-group__input--filled {
        & + label {
          font-weight: bold;
          font-size: 1.2rem;
          top: 0;
        }
      }

      &--uppercase {
        text-transform: uppercase;
      }

      &--date {
        font-size: 1.4rem;
      }

      &--licensePlate {
        text-transform: uppercase;
      }

      &--checkbox {
        display: flex;
        align-items: flex-start;
        height: auto !important;
        border: none !important;
        background-color: transparent !important;

        > input {
          display: none;

          &:checked + label {
            &::after {
              opacity: 1;
            }

            &::before {
              border: 2px solid ${ ({ theme }) => theme.companySecondary };
            }
          }
        }

        label {
          color: ${ ({ theme }) => theme.gray2 };
          font-size: 1.3rem;
          line-height: 2rem;
          font-weight: bold;
          padding: 0 0 0 24px;
          position: relative;
          cursor: pointer;

          &::before {
            content: "";
            display: inline-block;
            width: 16px;
            height: 16px;
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 4px;
            border: 2px solid ${ ({ theme }) => theme.gray2 };
            transition: var(--transition-all);
          }

          &::after {
            content: "";
            transition: var(--transition-all);
            display: inline-block;
            transform: rotate(45deg);
            height: 9px;
            width: 5px;
            border-bottom: 2px solid ${ ({ theme }) => theme.companySecondary };
            border-right: 2px solid ${ ({ theme }) => theme.companySecondary };
            position: absolute;
            left: 5.5px;
            top: 2px;
            opacity: 0;
          }

        }

        a {
          color: ${ ({ theme }) => theme.darkgray };
          font-weight: bold;
          position: relative;
          z-index: 0;
        }
      }

      &--upload {
        font-size: 1.6rem;
        color: rgba(0, 0, 0, 0.6);
        width: 100%;
        height: 100%;

        > label {
          display: none;
        }
      }

      &--error {
        border-color: ${ ({ theme }) => theme.invalid };
      }

      &--valid {
        border-color: ${ ({ theme }) => theme.valid };
      }

      &--invalid {
        border-color: ${ ({ theme }) => theme.invalid };
      }

    }

    &__label {
      position: absolute;
      color: ${ ({ theme }) => theme.gray2 };
      font-size: 1.4rem;
      transition: var(--transition-all);
      display: block;
      top: 22px;
      left: 0;


      &--error {
        color: ${ ({ theme }) => theme.invalid };
      }

      &--read-only {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

  }

  .input {
    opacity: 0.7;
    cursor: not-allowed;
    pointer-events: none;
    background-color: ${ ({ theme }) => theme.lightgray2 };
  }

  ${ ({ isHide }) => isHide && css`
    margin: 0;

    > label {
      display: none;
    }

    > input, > textarea, > select {
      display: none;
    }
  ` }

`

const UPLOAD = styled.div`
  font-size: 1.6rem;
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: relative;

  > label {
    display: none;
  }

  .files {
    height: 100%;
  }

  .files-dropzone {
    height: calc(100% - 16px);
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    border-radius: 4px;
    overflow: hidden;
    background-color: ${ ({ theme }) => theme.lightgray4 };

    > span {
      display: block;
      text-align: center;
      margin: 16px 0;
      width: 100%;
    }


    .preview {
      &:not(&.preview--full) {
        img {
          max-height: 120px;
          width: 120px;
          margin: 16px;
          border-radius: 4px;
        }
      }

      &--full {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .files-preview__extension {
      height: 120px;
      width: 120px;
      margin: 0 16px 16px;
      border-radius: 4px;
      background-color: ${ ({ theme }) => theme.gray };
      color: #FFF !important;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 48px;
        height: 48px;
      }
    }

    &__content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 16px);
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;

      .preview__icon {
        background-color: ${ ({ theme }) => theme.color7 };
        border-left: 2px solid ${ ({ theme }) => theme.light };
        border-top: 2px solid ${ ({ theme }) => theme.light };
        width: 39px;
        height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 4px;
        border-bottom-right-radius: 4px;
        position: absolute;
        bottom: 0;
        right: 0;

        svg {
          width: 24px;
          height: 24px;
          color: ${ ({ theme }) => theme.light };
        }
      }

      span {
        font-size: 1.4rem;
        font-weight: bold;
        color: ${ ({ theme }) => theme.color6Opaque };
      }
    }
  }
`

const SELECT = styled(INPUT)`
  position: relative;
  margin-bottom: 0;
  width: 100%;

  > input {
    background-position: 99% 5px;
    background-size: 20px;
    background-repeat: no-repeat;

    &:first-of-type {
      display: none;
    }

    &:last-of-type {
      &::placeholder {
        text-transform: initial !important;
      }
    }

    /* &:focus-within {
      & + ul {
        display: block;
      }
    } */
  }

  > ul {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    max-height: 350px;
    background-color: #FFF;
    overflow-y: auto;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;

    > li {
      font-size: 1.3rem;
      padding: 8px 8px;
        /* border-bottom: 1px solid ${ ({ theme }) => theme.lightgray }; */
      color: ${ ({ theme }) => theme.gray };
      cursor: pointer;
      transition: var(--transition-all);
      text-transform: uppercase;

      &:hover, &.active {
        background-color: ${ ({ theme }) => theme.lightgray };
      }
    }
  }

  > svg {
    position: absolute;
    top: 12px;
    right: 10px;
  }
`

const SELECTMULTI = styled(INPUT)`
  position: relative;
  margin-bottom: 0;
  width: 100%;

  .selected-options {
    border: 1px solid ${ ({ theme }) => theme.lightgray };
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: ${ ({ inputType }) => inputType === "checkbox" ? "unset" : "0 auto" };
    background-color: #FFF;
    font-size: 1.3rem;
    outline: none;
    padding: 8px 16px 8px 8px;
    height: 36px;
    width: ${ ({ inputType }) => inputType === "checkbox" ? "auto" : "100%" };
    color: ${ ({ theme }) => theme.darkgray };
    overflow: scroll;
    overflow-x: hidden;


    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    &:-webkit-autofill {
      -webkit-text-fill-color: ${ ({ theme }) => theme.darkgray } !important;
    }

    &:not(button) {
      &.disabled, &.read-only {
        opacity: 0.7;
        cursor: not-allowed;
        pointer-events: none;
        background-color: ${ ({ theme }) => theme.lightgray2 };
      }
    }

    > button {
      appearance: none;
      padding: 0;
      background-color: transparent;
      margin: 0;
      position: absolute;
      right: 2px;
      top: 8px;

      > img {
        width: 20px;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      margin-bottom: 8px;
      background-color: ${ ({ theme }) => theme.lightgray3 };
      padding: 2px 8px;
      border-radius: 10px;

      > span {
        font-size: 1.2rem;
        display: inline-block;
        margin-right: 4px;
      }

      > button {
        background-color: transparent;
        appearance: none;
        padding: 0;
        margin: 0 !important;
        line-height: 1;
      }
    }
  }

  > input {
    display: none;
  }

  > ul {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    max-height: 350px;
    background-color: #FFF;
    overflow-y: auto;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;

    > li {
      font-size: 1.3rem;
      padding: 8px 8px;
        /* border-bottom: 1px solid ${ ({ theme }) => theme.lightgray }; */
      color: ${ ({ theme }) => theme.gray };
      cursor: pointer;
      transition: var(--transition-all);
      text-transform: uppercase;

      &:hover, &.active {
        background-color: ${ ({ theme }) => theme.lightgray };
      }
    }
  }
`

const SWITCHER = styled.label<ISwitcherSC>`
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding: 0 8px;
  border-radius: 4px;
  background-color: ${ ({ theme }) => theme.lightgray2 };

  > input {
    display: none !important;
  }

  > span {
    font-size: 1.3rem;
    text-transform: initial;
    color: ${ ({ theme }) => theme.gray };
  }

  > div {
    width: 40px;
    height: 20px;
    border-radius: 24px;
    position: relative;
    transition: var(--transition-all);
    background-color: ${ ({ theme, active }) => active ? theme.color12 : theme.lightgray };
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      position: relative;
      transition: var(--transition-all);
      left: ${ ({ active }) => active ? "calc((100% - 14px) - 3px)" : "3px" };
      top: 3px;
      background-color: #FFF;
    }
  }
`

const DATEPICKER = styled.div <IDatepickerSC>`
  .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
  }

  span {
    color: ${ ({ theme }) => theme.gray };
    font-size: 1.2rem;
    text-transform: uppercase;
    margin: 0 8px;
    font-weight: bold;
  }
`

const RADIO = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  border: none !important;
  background-color: transparent !important;

  h4 {
    font-size: 1.4rem;
    font-weight: bold;
    color: ${ ({ theme }) => theme.gray };
    display: block;
    width: 100%;
    margin-bottom: 8px;
  }

  input {
    display: none;

    &:checked + label {
      &::after {
        opacity: 1;
        transform: scale(1);
      }

      &::before {
        border: 2px solid ${ ({ theme }) => theme.valid };
      }
    }

    &[disabled] {
      & + label {
        opacity: 0.6;
      }
    }
  }

  label {
    color: ${ ({ theme }) => theme.gray2 };
    font-size: 1.3rem;
    line-height: 2rem;
    font-weight: bold;
    padding: 0 0 0 24px;
    position: relative;
    cursor: pointer;
    margin-right: 24px;

    &::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      top: -1px;
      border-radius: 50%;
      border: 2px solid ${ ({ theme }) => theme.gray2 };
      transition: var(--transition-all);
    }

    &::after {
      content: "";
      transition: var(--transition-all);
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      left: 4px;
      top: 3px;
      background-color: ${ ({ theme }) => theme.valid };
      opacity: 0;
      transform: scale(0);
    }

  }
`

const CHECKBOX = styled.div`
  > input {
    display: none;
  }

  > ul {
    > li {
      font-size: 1.6rem;
    }
  }
`

const SERIALNUMBER = styled.div`
  > input {
    text-transform: uppercase;

    &:first-of-type {
      display: none;
    }
  }
`

const LICENSEPLATE = styled.div`
  > input {
    text-transform: uppercase;

    &:first-of-type {
      display: none;
    }
  }
`

export {
  INPUT,
  UPLOAD,
  SELECT,
  SELECTMULTI,
  SWITCHER,
  DATEPICKER,
  RADIO,
  CHECKBOX,
  SERIALNUMBER,
  LICENSEPLATE
}