import styled from "styled-components";
import { IHeaderSC } from "./Header.interface";
import { CONTAINER } from "../../styles/base.styles";
import { mediaConfig } from "../../styles/themeConfig";

const HEADER = styled.header <IHeaderSC>`
  width: 100%;
  text-align: center;
  z-index: 9;
  height: 52px;
  padding: 0 8px;
  margin-bottom: 32px;

  ${ mediaConfig.desktop } {
    height: 70px;
    margin-bottom: 70px;
  }

  > ${ CONTAINER } {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    > img {
      height: 24px;

      ${ mediaConfig.desktop } {
        height: 30px;
      }
    }

    > h1 {
      font-size: 1.4rem;
      font-weight: bold;
      color: ${ ({ theme }) => theme.darkgray };
      margin: 0;
      display: none;
    }
  }

  &.aside {
    ${ mediaConfig.desktop } {
      position: fixed;
      left: 0;
      top: 0;
      width: 120px;
      height: 100%;
      background-color: ${ ({ theme }) => theme.lightgray2 };
      border-right: 1px solid ${ ({ theme }) => theme.lightgray };
      box-shadow: inset 0 0 10px ${ ({ theme }) => theme.lightgray };

      > ${ CONTAINER } {
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding-top: 16px;
        padding-bottom: 16px;

        > img {
          height: auto;
          width: 100%;
          margin-top: 16px;
        }

        > h1 {
          font-size: 1.4rem;
          font-weight: bold;
          color: ${ ({ theme }) => theme.darkgray };
          margin: 0;
          display: none;
        }
      }
    }
  }

`


const MENU = styled.aside`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  ${ mediaConfig.desktop } {
    margin-top: 48px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .menu {
    &__item {
      width: 100%;
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &.active {
        > span {
          color: ${ ({ theme }) => theme.companySecondary };
        }
      }

      span {
        color: ${ ({ theme }) => theme.gray };
        font-size: 1.3rem;
      }

      button {
        margin-bottom: 6px;
      }

      &--logout {
        margin-top: auto;
      }
    }
  }

`;

export {
  HEADER,
  MENU
}