import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { ERoutes } from "./Router.interface";
import { Login } from "../pages/Login/Login";
import { Mobby } from "../pages/Mobby/Mobby";
import { StoreContext } from "../context/Store/StoreContext";
import { useContext } from "react";
import { Header } from "../components/Header/Header";
import { Register } from "../pages/Register/Register";
import { LAYOUT } from "../styles/base.styles";
import { ForgotPassword } from "../pages/Login/ForgotPassword";
import { ChangePasswordByForgot } from "../pages/Login/ChangePasswordByForgot";
import { ConfirmEmail } from "../pages/ConfirmEmail/ConfirmEmail";
import { List as PaymentMethodsList } from "../pages/PaymentMethods/List";
import { Register as PaymentMethodsRegister } from "../pages/PaymentMethods/Register";

const Layout = (): JSX.Element => {
  const { store } = useContext(StoreContext);
  return (
    <LAYOUT className={ `layout ${ store.isAuthenticated ? "authed" : "" }` }>
      <Header/>
      <main>
        <Outlet/>
      </main>
    </LAYOUT>
  )
}
const Router = (): JSX.Element => {
  const { store } = useContext(StoreContext);

  return (
    <Routes>
      <Route path={ ERoutes.Basename } element={ <Layout/> }>
        <Route index element={ <Login/> }/>
        <Route
          path={ ERoutes.Mobby }
          element={ store.isAuthenticated
            ? <Mobby/>
            : <Navigate to={ ERoutes.Login } replace={ true }/>
          }/>

        <Route path={ ERoutes.ForgotPassword } element={ <ForgotPassword/> }/>
        <Route path={ ERoutes.ChangePasswordByForgot } element={ <ChangePasswordByForgot/> }/>
        <Route path={ ERoutes.Register } element={ <Register/> }/>
        <Route path={ ERoutes.ConfirmEmail } element={ <ConfirmEmail/> }/>
        <Route path={ ERoutes.PaymentMethodsList } element={ <PaymentMethodsList/> }/>
        <Route path={ ERoutes.PaymentMethodsRegister } element={ <PaymentMethodsRegister/> }/>
      </Route>
    </Routes>
  )
}

export {
  Router
}