import { IAPIResponse } from "../types/api.interface";
import api from "./api";
import { IPostJWEToken, IPostLogin, IPostRegister } from "../pages/Register/Register.interface";
import { IGetJWETokenResponse, IPutUpdatePassword } from "../pages/Login/Login.interface";

const postRegister = (body: IPostRegister): Promise<IAPIResponse> => api.post(`/User`, body);

const postLogin = (body: IPostLogin): Promise<IAPIResponse> => api.post(`/User/Login`, body);

const postJWEToken = (body: IPostJWEToken): Promise<IAPIResponse<IGetJWETokenResponse>> => api.post(`/Token/Mobbyhub/Authorization`, body);

const putConfirEmail = (token: string): Promise<IAPIResponse> => api.put(`/User/confirm-email/`, { token });

const putForgotPassword = (email: string): Promise<IAPIResponse> => api.put(`/User/${ email }/send-forgot-password`);

const putUpdatePassword = (body: IPutUpdatePassword) => api.put(`/User/reset-password`, body);

export {
  postRegister as postRegisterService,
  postLogin as postLoginService,
  postJWEToken as postJWETokenService,
  putConfirEmail as putConfirEmailService,
  putForgotPassword as putForgotPasswordService,
  putUpdatePassword as putUpdatePasswordService,
}