import styled from "styled-components";
import { mediaConfig } from "../../styles/themeConfig";
import { TITLE } from "../../styles/base.styles";

const MOBBY = styled.section`
  padding-top: 48px;

  .mobby {
    &__wrapper {
      display: block;
      height: calc(100vh - 84px);
      width: calc(100vw);

      ${ mediaConfig.desktop } {
        height: calc(100vh);
        width: 100%;
        max-width: 488px;
        margin: 0 auto;
      }

      > ${ TITLE } {
        margin-bottom: 24px;
      }

      iframe {
        display: block;
        height: calc(100vh - 84px);
        width: 100%;

        ${ mediaConfig.desktop } {
          height: calc(100% - 160px);
          max-width: 488px;
          margin: 0 auto;
        }
      }
    }
  }

`

export {
  MOBBY,
}