import { removeBlankSpaceUtil } from "./dump.util";
import { IValidationFormDictionary } from "../types/global.interface";
import { isDevUtil, isTestUtil } from "./env.util";
import { EInputType } from "../components/Input/Input.interface";

const validation: IValidationFormDictionary = {
  licensePlate: {
    validation: value => {
      return /[A-Za-z]{3}[0-9][0-9A-Za-z][0-9]{2}/.test(value);
    },
    errorMessage: "Placa inválida",
  },
  renavam: {
    validation: value => {
      if (!/^[0-9]*$/.test(value)) return false;
      return true;
    },
    errorMessage: "Renavam inválido"
  },
  document: {
    validation: value => {
      return /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/.test(value);
    },
    errorMessage: "CPF/CNPJ inválido"
  },
  rg: {
    validation: value => {
      return /([a-zA-Z0-9]{6,9})/.test(value)
    },
    errorMessage: 'Documento inválido',
  },
  email: {
    validation: value => {
      return /\S+@\S+\.\S+/.test(value)
    },
    errorMessage: 'E-mail inválido',
  },
  zipcode: {
    validation: value => {
      value = value.replaceAll("-", "");
      return /^[0-9]{8}$/.test(value)
    },
    errorMessage: "Digite um CEP válido"
  },
  cardHolderName: {
    validation: value => {
      return /([A-Za-z]{2,})+\s([A-Za-z]{1,})/.test(value)
    },
    errorMessage: 'Necessário Nome e Sobrenome',
  },
  cardNumber: {
    validation: value => {
      value = removeBlankSpaceUtil(value);
      const regVisa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
      const regMaster = new RegExp("^5[1-5][0-9]{14}$");
      const regAmex = new RegExp("^3[47][0-9]{13}$");
      const regElo = new RegExp("^(40117[8-9]|431274|438935|451416|457393|45763[1-2]|506(699|7[0-6][0-9]|77[0-8])|509\\d{3}|504175|627780|636297|636368|65003[1-3]|6500(3[5-9]|4[0-9]|5[0-1])|6504(0[5-9]|[1-3][0-9])|650(4[8-9][0-9]|5[0-2][0-9]|53[0-8])|6505(4[1-9]|[5-8][0-9]|9[0-8])|6507(0[0-9]|1[0-8])|65072[0-7]|6509(0[1-9]|1[0-9]|20)|6516(5[2-9]|[6-7][0-9])|6550([0-1][0-9]|2[1-9]|[3-4][0-9]|5[0-8]))");
      const regHipercard = new RegExp("^(606282\\d{10}(\\d{3})?)|(3841(0|4|6)0\\d{13})$");

      if (regVisa[Symbol.match](value))
        return true;
      else if (regMaster[Symbol.match](value))
        return true;
      else if (regAmex[Symbol.match](value))
        return true;
      else if (regElo[Symbol.match](value))
        return true;
      else if (regHipercard[Symbol.match](value))
        return true;
      else
        return false;
    },
    errorMessage: "Bandeira do cartão não aceita",
  },
  cardExpireDate: {
    validation: value => {
      const
        month = Number(value.split("/")[0]),
        year = Number(value.split("/")[1]),
        currentMonth = new Date().getMonth() + 1,
        currentYear = Number(new Date().getFullYear().toString().substr(-2));

      if (year < currentYear) return false;
      if (year === currentYear && month < currentMonth) return false;
      return /^(0[1-9]|1[0-2])\/([0-9]{2})$/.test(value);
    },
    errorMessage: "Digite uma data válida"
  },
  cvv: {
    validation: value => {
      value = value.replaceAll(" ", "");
      return /^[0-9]{3,4}$/.test(value);
    },
    errorMessage: "Digite um CVC válido"
  },
  uf: {
    validation: value => {
      return /[A-Za-z]{2}/.test(value)
    },
    errorMessage: "Estado deve conter apenas letras"
  },
  streetNumber: {
    validation: value => {
      return /[0-9]{4}[0-9Vv][0-9]{11}/.test(value)
    },
    errorMessage: "Número inválido"
  },
  chargeAmount: {
    validation: value => {
      value = Number(value.replaceAll("R$", "").replaceAll(".", "").replace(",", "."));
      if (value > 999999999.99) return false;
      return true;
    },
    errorMessage: "Valor máximo R$999.999.999,99"
  },
  chargeLimitPerVehicle: {
    validation: value => {
      value = Number(value.replaceAll("R$", "").replaceAll(".", "").replace(",", "."));
      if (value > 999999999.99) return false;
      return true;
    },
    errorMessage: "Valor máximo R$999.999.999,99"
  },
  chargeMinimumPeriod: {
    validation: value => {
      console.log(value);
      if (Number(value) === 0) return false;
      return true;
    },
    errorMessage: "Valor deve ser maior que 0."
  },
  serialNumber: {
    validation: value => {
      value = removeBlankSpaceUtil(value);
      if (value.length !== 16) return false;
      return true;
    },
    errorMessage: "Número da Tag inválido"
  },
  verificationCode: {
    validation: value => {
      value = removeBlankSpaceUtil(value);
      if (value.length !== 6) return false;
      return true;
    },
    errorMessage: "Código de verificação deve conter 6 dígitos"
  },
  testeError: {
    validation: () => false,
    errorMessage: "Erro genérico"
  },
}

const validateField = (field: string, value: any, noValidate: boolean | undefined, type: EInputType | undefined): boolean | string | undefined => {
  if (noValidate) return true;

  if (type === EInputType.LicensePlate || type === EInputType.Document) {
    if (validation[type].validation(value)) return true;

    return validation[type].errorMessage;

  }

  if (!validation[field]) {
    if (!isTestUtil && isDevUtil) console.warn(`validação do campo [${ field }], não implementada!`);
    return true;
  }

  if (validation[field].validation(value)) return true;

  return validation[field].errorMessage;
}

const stateList = [
  { id: "AC", name: "AC" },
  { id: "AL", name: "AL" },
  { id: "AM", name: "AM" },
  { id: "AP", name: "AP" },
  { id: "BA", name: "BA" },
  { id: "CE", name: "CE" },
  { id: "DF", name: "DF" },
  { id: "ES", name: "ES" },
  { id: "GO", name: "GO" },
  { id: "MA", name: "MA" },
  { id: "MT", name: "MT" },
  { id: "MS", name: "MS" },
  { id: "MG", name: "MG" },
  { id: "PA", name: "PA" },
  { id: "PB", name: "PB" },
  { id: "PR", name: "PR" },
  { id: "PE", name: "PE" },
  { id: "PI", name: "PI" },
  { id: "RJ", name: "RJ" },
  { id: "RN", name: "RN" },
  { id: "RO", name: "RO" },
  { id: "RS", name: "RS" },
  { id: "RR", name: "RR" },
  { id: "SC", name: "SC" },
  { id: "SE", name: "SE" },
  { id: "SP", name: "SP" },
  { id: "TO", name: "TO" }
]

export {
  validateField as validateFieldUtil,
  stateList as stateListUtil,
}