export enum ERoutes {
  Basename = "/",
  Login = "/",
  Register = "/Register",
  ForgotPassword = "/ForgotPassword",
  ChangePasswordByForgot = "/ForgotPassword/ChangePassword/:token",
  ConfirmEmail = "/ConfirmEmail/:token",
  Mobby = "/Mobby",
  PaymentMethodsList = "/PaymentMethods/List",
  PaymentMethodsRegister = "/PaymentMethods/Register",
  Help = "/Help",
  Transactions = "/Transactions",
  Services = "/Services",

}