import { LIST } from "./PaymentMethods.styles";
import { SUBTITLE, TITLE } from "../../styles/base.styles";
import { Warning } from "../../components/Warning/Warning";
import { Button } from "../../components/Button/Button";
import { ERoutes } from "../../router/Router.interface";
import { useNavigate } from "react-router-dom";

const List = () => {
  const navigate = useNavigate();

  return (
    <LIST>
      <div className="list__wrapper">
        <TITLE>Meus cartões</TITLE>
        <SUBTITLE>Aqui ficam os cartões cadastrados por você para realizar as compras e/ou pagamentos.</SUBTITLE>

        <div className={ "list__warning" }>
          <Warning
            title={ "Ops! Você ainda possui cartão de crédito cadastrado." }
            message={ <p>Para fazer pagamentos e compras em nossa plataforma, é necessário ter pelo menos um cartão de
              crédito cadastrado. Cadastre seu cartão e comece a usar os nossos serviços.</p> }/>

          <Button
            text={ "Cadastrar cartão" }
            type={ "button" }
            action={ () => navigate(ERoutes.PaymentMethodsRegister) }/>
        </div>
      </div>
    </LIST>
  )
}

export { List }