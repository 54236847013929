import { REGISTER } from "./PaymentMethods.styles";
import { ROW, SUBTITLE, TITLE } from "../../styles/base.styles";
import { InputProvider } from "../../components/Input/Input.context";
import { useForm } from "react-hook-form";
import { Input } from "../../components/Input/Input";
import { EInputType } from "../../components/Input/Input.interface";
import {
  cartaoAmericanExpressAsset,
  cartaoEloAsset,
  cartaoHipercardAsset,
  cartaoMastercardAsset,
  cartaoVisaAsset
} from "../../assets";
import { ProgressBar } from "../../components/Loader/Loader";
import { Button } from "../../components/Button/Button";
import { useEffect, useState } from "react";
import { removeBlankSpaceUtil } from "../../utils/dump.util";

const Register = () => {
  const methods = useForm();
  const [ progress, setProgress ] = useState<number>(30);
  const [ currentStep, setCurrentStep ] = useState("card");
  const [ selectedCardBrand, setSelectedCardBrand ] = useState<any>(null);
  const mockAllowedConfigurations = {
    creditCards: [ { name: "visa" }, { name: "mastercard" }, { name: "elo" }, { name: "hipercard" }, { name: "amex" } ]
  }
  const cardNumberWatch = methods.watch("cardNumber");

  const addCreditCardProceed = (data: any) => {

  }
  const getCardBrandByName = (brand: string) => {
    switch (brand) {
      case "american-express":
        return cartaoAmericanExpressAsset;
      case "amex":
        return cartaoAmericanExpressAsset;
      case "elo":
        return cartaoEloAsset;
      case "hipercard":
        return cartaoHipercardAsset;
      case "mastercard":
        return cartaoMastercardAsset;
      case "visa":
        return cartaoVisaAsset;
      default:
        return ""
    }
  }

  const getCardBrandByNumber = (number: string) => {
    const regVisa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
    const regMaster = new RegExp("^5[1-5][0-9]{14}$");
    const regAmex = new RegExp("^3[47][0-9]{13}$");
    const regElo = new RegExp("^(40117[8-9]|431274|438935|451416|457393|45763[1-2]|506(699|7[0-6][0-9]|77[0-8])|509\\d{3}|504175|627780|636297|636368|65003[1-3]|6500(3[5-9]|4[0-9]|5[0-1])|6504(0[5-9]|[1-3][0-9])|650(4[8-9][0-9]|5[0-2][0-9]|53[0-8])|6505(4[1-9]|[5-8][0-9]|9[0-8])|6507(0[0-9]|1[0-8])|65072[0-7]|6509(0[1-9]|1[0-9]|20)|6516(5[2-9]|[6-7][0-9])|6550([0-1][0-9]|2[1-9]|[3-4][0-9]|5[0-8]))");
    const regHipercard = new RegExp("^(606282\\d{10}(\\d{3})?)|(3841(0|4|6)0\\d{13})$");

    console.log("number", number)
    
    if (regVisa[Symbol.match](number))
      setSelectedCardBrand(getCardBrandByName("visa"));
    else if (regMaster[Symbol.match](number))
      setSelectedCardBrand(getCardBrandByName("master"));
    else if (regAmex[Symbol.match](number))
      setSelectedCardBrand(getCardBrandByName("amex"));
    else if (regElo[Symbol.match](number))
      setSelectedCardBrand(getCardBrandByName("elo"));
    else if (regHipercard[Symbol.match](number))
      setSelectedCardBrand(getCardBrandByName("hipercard"));
    else
      setSelectedCardBrand(null);
  }

  useEffect(() => {
    getCardBrandByNumber(removeBlankSpaceUtil(cardNumberWatch));
  }, [ cardNumberWatch ])

  return (
    <REGISTER>
      <div className="register__wrapper">
        <TITLE>Cadastro de cartão</TITLE>
        <SUBTITLE>Informe os dados do cartão de crédito</SUBTITLE>
        <ProgressBar percent={ progress }/>
        <InputProvider onValid={ addCreditCardProceed } methods={ methods }>
          <div className={ `register__step ${ currentStep === "card" ? "active" : "" }` }>
            <div className={ "register__card-number-field" }>
              <Input
                required={ currentStep === "card" }
                name={ "cardNumber" }
                label={ "Número do cartão" }
                format={ "#### #### #### #####" }
                type={ EInputType.Mask }/>
              { selectedCardBrand && <img src={ selectedCardBrand } alt="ico"/> }
            </div>
            <ROW>
              <Input
                required={ currentStep === "card" }
                name={ "cardExpireDate" }
                label={ "Validade" }
                format={ "##/####" }
                type={ EInputType.Mask }/>
              <Input
                required={ currentStep === "card" }
                name={ "cvv" }
                label={ "Código de segurança" }
                type={ EInputType.Number }/>
            </ROW>

            <Input
              required={ currentStep === "card" }
              name={ "cardHolderName" }
              label={ "Nome impresso no cartão" }
              type={ EInputType.Text }/>
            <Input
              required={ currentStep === "card" }
              name={ "CPF/CNPJ" }
              label={ "Número do cartão" }
              type={ EInputType.Document }/>

            <h5>Bandeiras de cartões aceitas</h5>
            <ul className={ "register__card-brands" }>
              { mockAllowedConfigurations.creditCards.map((flag: any) =>
                <li key={ flag.name }>
                  <img src={ getCardBrandByName(flag.name) } alt="ico"/>
                </li>
              ) }
            </ul>
          </div>

          <div className={ `register__step ${ currentStep === "address" ? "active" : "" }` }>
            <Input
              required={ currentStep === "address" }
              name={ "zipcode" }
              label={ "CEP" }
              format={ "#####-###" }
              type={ EInputType.Mask }/>
            <Input
              required={ currentStep === "address" }
              name={ "state" }
              label={ "State" }
              type={ EInputType.Select }/>
            <Input
              required={ currentStep === "address" }
              name={ "city" }
              label={ "Cidade" }
              type={ EInputType.Text }/>
            <Input
              required={ currentStep === "address" }
              name={ "neighborhood" }
              label={ "Bairro" }
              type={ EInputType.Text }/>
            <Input
              required={ currentStep === "address" }
              name={ "street" }
              label={ "Endereço" }
              type={ EInputType.Text }/>
            <Input
              required={ currentStep === "address" }
              name={ "streetNumber" }
              label={ "Número" }
              type={ EInputType.Number }/>
            <Input
              name={ "complement" }
              label={ "Complemento" }
              type={ EInputType.Text }/>
          </div>

          <div className={ `register__step ${ currentStep === "contact" ? "active" : "" }` }>
            <Input
              required={ currentStep === "contact" }
              name={ "email" }
              label={ "E-mail" }
              type={ EInputType.Email }/>
            <Input
              required={ currentStep === "contact" }
              name={ "phone" }
              label={ "Telefone" }
              format={ "(##) #####-####" }
              type={ EInputType.Mask }/>
          </div>

          <Button text={ currentStep === "contact" ? "Finalizar" : "Continuar" } type={ "submit" }/>
        </InputProvider>
      </div>
    </REGISTER>
  )
}

export {
  Register
}